import { useEffect, useState, useRef } from 'react'
import { useTranslation } from "react-i18next";
import helpers from "@drapp/helpers";
import { Link } from '@reach/router'

import useMutation from 'hooks/useMutation'
import { IncreaseCheckoutLite } from './IncreaseCheckoutLite'
let clone = window.structuredClone || JSON.clone

export function Form(props) {
  let { t } = useTranslation();
  let [subscription, setSubscription] = useState({ paymentMethod: 'card' })
  let [warnings, setWarning] = useState({})
  let [mutate, mutation] = useMutation(window._l(`/subscriptions`).substr(1)); 
  let [view, setView] = useState('init')

  let onChangeSubscription = (key, value) => {
    let payload = clone(subscription)
    payload[key] = value
    setSubscription(payload)
  }

  let onUpdatePaymentMethod = status => {
    if (status === 'PAYMENT_SUCCESS') {
      setView('ready')
    } else if (status === 'PAYMENT_ERROR') {

    } else if (status === 'PAYMENT_IN_PROCESS') {

    } else if (status === 'LOAD_ERROR') {

    } else if (status === 'LOAD_SUCCESS') {

    }
  }

  let save = async () => {
    let {
      email         = '',
      lastName      = '',
      firstName     = '',
      taxID         = '',
      addressLine1  = '',
      zipCode       = '',
      phone         = '',
      city          = '',
      paymentMethod = 'card',
      cbu           = '',
    } = subscription

    let warnings = {}

    if (!helpers.isEmail(email)) warnings.email = t('subscriptions_form_invalid_email', 'Ingrese un email valido')
    if (lastName.replace(/\s/g, '').length <= 2) warnings.lastName = t('subscriptions_form_invalid_last_name', 'Ingrese un apellido valido')
    if (firstName.replace(/\s/g, '').length <= 2) warnings.firstName = t('subscriptions_form_invalid_first_name', 'Ingrese un nombre valido')
    if (taxID.replace(/\D/g, '').length < 6) warnings.taxID = t('subscriptions_form_invalid_tax_id', 'Ingrese un DNI valido')
    if (addressLine1.replace(/\s/g, '').length < 6) warnings.addressLine1 = t('subscriptions_form_invalid_address_line1', 'Ingrese una direccion valida')
    if (city.replace(/\s/g, '').length < 4) warnings.city = t('subscriptions_form_invalid_city', 'Ingrese una ciudad valida')
    if (phone.replace(/\D/g, '').length < 6) warnings.phone = t('subscriptions_form_invalid_phone_number', 'Ingrese un numero de telefono valid. Codigo de area + numero de telefono. Sin el cero ni el 15')
    if (zipCode.replace(/\D/g, '').length < 4) warnings.zipCode = t('subscriptions_form_invalid_zip_code', 'Ingrese un codigo postal valido')
    if (paymentMethod === 'cbu' && cbu.replace(/\D/g, '').length !== 22) warnings.cbu = t('subscriptions_form_invalid_cbu', 'EL CBU debe tener 22 digitos')

    warnings.keys = Object.keys(warnings)
    setWarning(warnings)
    if (warnings.keys.length) return

    mutate({ ...subscription }, {
      onSuccess: data => {
        if (paymentMethod === 'cbu') {
          setView('ready')
        } else {
          setView(paymentMethod)
        }
        setSubscription(data)
      },
      onError: () => {
        setView('error')
      }
    })
  }

  return (
    <div style={{ top: 0, left: 0, paddingTop: '80px' }} className="position-fixed overflow-auto bg-white w-100 h-100 px-4 pb-4">
      <div style={{ opacity: mutation.isLoading ? .5 : 1 }}>
        {view === 'error' ? (
          <div style={{ width: 480 }} className="mx-auto text-center">
            <div className="alert alert-danger">{t('subscription_save_error', 'Se produjo un error')}</div>
            <div><Link to={window._l(`/events/agenda`)}>{t('subscriptions_goto_agenda', 'Ir a la Agenda')}</Link></div>
          </div>
        ) : (view === 'loading' || mutation.isLoading) ? (
          <div className="text-center mt-3"><span className="spinner-border spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status"></span></div>
        ) : view === 'ready' ? (
          <div style={{ width: 480 }} className="mx-auto text-center">
            <div className="alert alert-success">{t('subscription_create_success', 'Los datos fueron guardados correctamente')}</div>
            <div><Link to={window._l(`/events/agenda`)}>{t('subscriptions_goto_agenda', 'Ir a la Agenda')}</Link></div>
          </div>
        ) : !!subscription?.id && view === 'card' ? (
          <div style={{ maxWidth: 1024 }} className="mx-auto">
            <h5>{t('subscriptions_payment_method_form_title', 'Formulario de suscripcion')}</h5>
            <IncreaseCheckoutLite onUpdate={onUpdatePaymentMethod} subscriptionID={subscription?.id} />
          </div>
        ) : view === 'init' && !!subscription && (
          <div style={{ maxWidth: 1024 }} className="mx-auto">
            <h5>{t('subscriptions_payment_method_form_title', 'Formulario de suscripcion')}</h5>
            <div>
              <div className="d-lg-flex align-items-center gap-3">
                <div className="col-lg">
                  <label htmlFor={`billingLastName`} className="form-label text-nowrap mt-3 mt-lg-0">{t('billing_last_name', 'Apellido')}</label>
                  <input
                    type="text"
                    className={`form-control ${warnings.lastName ? 'is-invalid' : ''}`}
                    id={`billingLastName`}
                    value={subscription.lastName || subscription.customer?.last_name || ''}
                    onChange={e => onChangeSubscription('lastName', e.target.value)}
                  />
                  {warnings.lastName && (<div className="invalid-feedback">{warnings.lastName}</div>)}
                </div>
                <div className="col-lg">
                  <label htmlFor={`billingFirstName`} className="form-label text-nowrap mt-3 mt-lg-0">{t('billing_first_name', 'Nombre')}</label>
                  <input
                    type="text"
                    className={`form-control ${warnings.firstName ? 'is-invalid' : ''}`}
                    id={`billingFirstName`}
                    value={subscription.firstName || subscription.customer?.first_name || ''}
                    onChange={e => onChangeSubscription('firstName', e.target.value)}
                  />
                  {warnings.firstName && (<div className="invalid-feedback">{warnings.firstName}</div>)}
                </div>
                <div className="col-lg">
                  <label htmlFor={`billingTaxIDDNI`} className="form-label text-nowrap mt-3 mt-lg-0">{t('billing_tax_id_dni', 'DNI')}</label>
                  <input
                    type="text"
                    className={`form-control ${warnings.taxID ? 'is-invalid' : ''}`}
                    id={`billingTaxIDDNI`}
                    value={subscription.taxID || subscription.customer?.tax_id || ''}
                    onChange={e => onChangeSubscription('taxID', e.target.value)}
                  />
                  {warnings.taxID && (<div className="invalid-feedback">{warnings.taxID}</div>)}
                </div>
              </div>

              <div className="d-lg-flex align-items-center gap-3 my-3">
                <div className="col-lg">
                  <label htmlFor={`billingAddressLine1`} className="form-label text-nowrap mt-3 mt-lg-0">{t('billing_address_line_1', 'Linea direccion 1')}</label>
                  <input
                    type="text"
                    className={`form-control ${warnings.addressLine1 ? 'is-invalid' : ''}`}
                    id={`billingAddressLine1`}
                    value={subscription.addressLine1 || subscription.customer?.billing_info?.address_line_1 || ''}
                    onChange={e => onChangeSubscription('addressLine1', e.target.value)}
                  />
                  {warnings.addressLine1 && (<div className="invalid-feedback">{warnings.addressLine1}</div>)}
                </div>
                <div className="col-lg">
                  <label htmlFor={`billingAddressStateCode`} className="form-label text-nowrap mt-3 mt-lg-0">{t('billing_address_state_code', 'Provincia')}</label>
                  <select
                    className={`form-select ${warnings.stateCode ? 'is-invalid' : ''}`}
                    id={`billingAddressStateCode`}
                    value={subscription.stateCode || subscription.customer?.billing_info?.state_iso_code|| 'AR-C'}
                    onChange={e => onChangeSubscription('stateCode', e.target.value)}
                  >
                    <option value="AR-B">Buenos Aires </option>
                    <option value="AR-C">Ciudad Autónoma de Buenos Aires</option>
                    <option value="AR-K">Catamarca</option>
                    <option value="AR-H">Chaco</option>
                    <option value="AR-U">Chubut </option>
                    <option value="AR-X">Córdoba</option>
                    <option value="AR-W">Corrientes </option>
                    <option value="AR-E">Entre Ríos </option>
                    <option value="AR-P">Formosa</option>
                    <option value="AR-Y">Jujuy</option>
                    <option value="AR-L">La Pampa </option>
                    <option value="AR-F">La Rioja </option>
                    <option value="AR-M">Mendoza</option>
                    <option value="AR-N">Misiones </option>
                    <option value="AR-Q">Neuquén</option>
                    <option value="AR-R">Río Negro</option>
                    <option value="AR-A">Salta</option>
                    <option value="AR-J">San Juan </option>
                    <option value="AR-D">San Luis </option>
                    <option value="AR-Z">Santa Cruz </option>
                    <option value="AR-S">Santa Fe </option>
                    <option value="AR-G">Santiago del Estero</option>
                    <option value="AR-V">Tierra del Fuego </option>
                    <option value="AR-T">Tucumán</option>
                  </select>
                  {warnings.stateCode && (<div className="invalid-feedback">{warnings.stateCode}</div>)}
                </div>

                <div className="col-lg">
                  <label htmlFor={`billingAddressCity`} className="form-label text-nowrap mt-3 mt-lg-0">{t('billing_address_city', 'Ciudad')}</label>
                  <input
                    type="text"
                    className={`form-control ${warnings.city ? 'is-invalid' : ''}`}
                    id={`billingAddressCity`}
                    value={subscription.city || subscription.customer?.billing_info?.city || ''}
                    onChange={e => onChangeSubscription('city', e.target.value)}
                  />
                  {warnings.city && (<div className="invalid-feedback">{warnings.city}</div>)}
                </div>
              </div>

              <div className="d-lg-flex align-items-center gap-3">
                <div className="col-lg">
                  <label htmlFor={`billingAddressZipCode`} className="form-label text-nowrap mt-3 mt-lg-0">{t('billing_address_zip_code', 'Codigo Postal')}</label>
                  <input
                    type="text"
                    className={`form-control ${warnings.zipCode ? 'is-invalid' : ''}`}
                    id={`billingAddressZipCode`}
                    value={subscription.zipCode || subscription.customer?.billing_info?.zip_code || ''}
                    onChange={e => onChangeSubscription('zipCode', e.target.value)}
                  />
                  {warnings.zipCode && (<div className="invalid-feedback">{warnings.zipCode}</div>)}
                </div>

                <div className="col-lg">
                  <label htmlFor={`billingEmail`} className="form-label text-nowrap mt-3 mt-lg-0">{t('billing_email', 'Email')}</label>
                  <input
                    type="email"
                    className={`form-control ${warnings.email ? 'is-invalid' : ''}`}
                    id={`billingEmail`}
                    value={subscription.email || subscription.customer?.email || ''}
                    onChange={e => onChangeSubscription('email', e.target.value)}
                  />
                  {warnings.email && (<div className="invalid-feedback">{warnings.email}</div>)}
                </div>

                <div className="col-lg">
                  <label htmlFor={`billingPhone`} className="form-label text-nowrap mt-3 mt-lg-0">{t('billing_phone', 'Telefono')}</label>
                  <input
                    type="text"
                    className={`form-control ${warnings.phone ? 'is-invalid' : ''}`}
                    id={`billingPhone`}
                    value={subscription.phone || subscription.customer?.billing_info?.phone || ''}
                    onChange={e => onChangeSubscription('phone', e.target.value)}
                  />
                  {warnings.phone && (<div className="invalid-feedback">{warnings.phone}</div>)}
                </div>
              </div>

              <div className="mt-3">
                <div>
                  <div className="form-check-inline fw-bold">
                    {t('subscriptions_payment_method_long', 'Medio pago')}
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      onChange={() => onChangeSubscription('paymentMethod', 'card')}
                      checked={subscription.paymentMethod === 'card'}
                      type="radio"
                      name="subscriptionPaymentMethod"
                      id="subscriptionPaymentMethod1"
                    />
                    <label className="form-check-label" htmlFor="subscriptionPaymentMethod1">
                      {t('subscriptions_payment_method_card', 'Tarjeta de credito')}
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      onChange={() => onChangeSubscription('paymentMethod', 'cbu')}
                      checked={subscription.paymentMethod === 'cbu'}
                      type="radio"
                      name="subscriptionPaymentMethod"
                      id="subscriptionPaymentMethod2"
                    />
                    <label className="form-check-label" htmlFor="subscriptionPaymentMethod2">
                      {t('subscriptions_payment_method_cbu', 'Debito en cuenta - CBU')}
                    </label>
                  </div>
                  <div className={`d-inline-block ${subscription.paymentMethod === 'cbu' ? '' : 'invisible'}`}>
                    <div className="d-flex gap-3 align-items-center">
                      <input
                        type="text"
                        className={`form-control ${warnings.cbu ? 'is-invalid' : ''}`}
                        id={`cbu`}
                        style={{ width: '265px', minWidth: '265px' }}
                        value={subscription.cbu || ''}
                        placeholder={subscription.payment_method?.data?.number || t('cbu', 'Nro. CBU')}
                        onChange={e => onChangeSubscription('cbu', e.target.value.replace(/\D/g, ''))}
                      />
                      {warnings.cbu && (<div className="invalid-feedback">{warnings.cbu}</div>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button type="button" disabled={mutation.isLoading} onClick={save} className="btn btn-primary text-first-up">
                {mutation.isLoading && (
                  <span className="spinner-border spinner-border-sm" role="status"></span>
                )} {t('next', 'siguiente')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
