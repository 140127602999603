import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
// import { Tooltip, Toast, Popover } from "bootstrap";
import { Auth0Provider } from "@auth0/auth0-react";
import { Router } from "@reach/router";
import "intersection-observer";

import { QueryClient, QueryClientProvider } from "react-query";

import "./index.scss";
import "./config/window";
import "./config/i18n";
import Protected from "./Protected";
import App from "./App/App";
import Obsolete from "./Components/Obsolete";

const {
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_MAINTENANCE,
} = process.env;

const queryClient = new QueryClient();

const Loader = () => (
  <div className="vh-100 p-5 d-flex justify-content-center align-items-center">
    <div className="spinner-border" role="status"></div>
  </div>
);


const Main = () => {
  if (REACT_APP_MAINTENANCE) return (
    <div className="vh-100 d-flex flex-column align-items-center justify-content-center p-3">
      <h2>Interrupcion temporal</h2>
      <p>Nuestro equipo está trabajando para normalizar el sistema lo antes posible</p>
    </div>
  )

  let redirectUri = window.location.origin
  try {
    let searchParams = new URLSearchParams(window.location.search)
    if (!searchParams.get('next')) {
      let params = new URLSearchParams({ next: window.location.pathname })
      if (params.get('next')) {
        redirectUri = `${window.location.origin}?${params.toString()}`
      }
    }
  } catch {}

  return (
    <React.Suspense fallback={<Loader />}>
      {window.Modernizr.es6object ? (
        <Auth0Provider
          domain={REACT_APP_AUTH0_DOMAIN}
          clientId={REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={redirectUri}
          audience={REACT_APP_AUTH0_AUDIENCE}
        >
          <QueryClientProvider client={queryClient}>
            <Protected>
              <Router>
                <App default />
              </Router>
            </Protected>
          </QueryClientProvider>
        </Auth0Provider>
      ) : (
        <Obsolete />
      )}
    </React.Suspense>
  );
}


ReactDOM.render(<Main />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
