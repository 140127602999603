import { lazy, useEffect, useState } from "react";
import { Router, useLocation, useNavigate } from "@reach/router";
import ReactTooltip from "react-tooltip";

import actions from "./actions";
import Suspense from "./Suspense/Suspense";
import useQuery from "../hooks/useQuery";
import useUser from "../hooks/useUser";
import useSocket from 'hooks/useSocket'
import useStoreRooms from "./Room/useStore";
import Navbar from "./Navbar/Navbar";
import Events from "./Events/Events";
import Consumers from "./Consumers/Consumers";
import CheckLicenses from "./CheckLicenses/CheckLicenses"
import { Form as SubscriptionForm } from "./Setup/Subscription/Form"

const Notify = lazy(() => import("./Notify/Notify"));
const Room = lazy(() => import("./Room/Room"));
const Consumer = lazy(() => import("./Consumers/Modal/Modal"));
const Location = lazy(() => import("./Location/Modal"));
const MessagesForm = lazy(() => import("./Messages/Form/Form"));
const Onboarding = lazy(() => import("./Onboarding/Onboarding"));
const Setup = lazy(() => import("./Setup/Setup"));
const Locked = lazy(() => import("./Teams/Locked"));
const Prescription = lazy(() => import("./Prescription/Prescription"));
const Monitor = lazy(() => import("./Monitor/Monitor"));

const {
  REACT_APP_VERSION,
  REACT_APP_GIT_SHA,
  REACT_APP_IC,
  REACT_APP_API_URL
} = process.env

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const teams = useQuery("teams", {
    initialData: [],
  });
  const user = useUser();
  const rooms = useStoreRooms();
  const [team, setTeam] = useState();
  const socket = useSocket()
  const [source, setSource] = useState();

  const fetchSource = async (id) => {
    fetch(`${REACT_APP_API_URL}/intercom/source?id=${id}`)
      .then((res) => res.json())
      .then(data => setSource(data ? data : "drapp"));
  }

  useEffect(() => {
    if (source && window.intercomSettings.company) {
      window.intercomSettings.company.Source = source;
      window.Intercom("update", window.intercomSettings);
    }
  }, [source])

  useEffect(() => {
    actions.onChangeLocation();
  }, [location.pathname]);

  useEffect(() => {
    if (window.Intercom) {
      window.intercomSettings = window.intercomSettings || {
        app_id: REACT_APP_IC,
      };

      if (user.state.name) {
        window.intercomSettings.name = user.state.name;
      }

      if (REACT_APP_VERSION) {
        window.intercomSettings.version = REACT_APP_VERSION;
      }

      if (REACT_APP_GIT_SHA) {
        window.intercomSettings.commit = REACT_APP_GIT_SHA;
      }

      if (user.state.email) {
        window.intercomSettings.email = user.state.email;
      }

      if (user.state.uid) {
        window.intercomSettings.user_id = user.state.uid;
      }

      if (window.intercomSettings.email) {
        window.intercomSettings.dm2 = user.state.dm2;
      }

      window.intercomSettings.hide_default_launcher = window.innerWidth < 992
      window.intercomSettings.custom_launcher_selector = "#ic-launcher"
      window.Intercom("boot", window.intercomSettings);
    }
  }, [user.state.name, user.state.email, user.state.uid]);

  useEffect(() => {
    if (
      teams.isFetched &&
      user.query.isFetched &&
      user.state.email &&
      location.pathname !== "/teams/new"
    ) {
      const team =
        teams.data.find((team) => location.pathname.includes(team.id)) ||
        teams.data.find((team) => team.id === user.state.teamID) ||
        teams.data.find((team) => team.default) ||
        teams.data[0];
      let search = new URLSearchParams(location.search)
      if (search.get('next')) {
        navigate(search.get('next'), { replace: true });
      } else if (!team && teams.data.length === 0) {
        navigate("/teams/new", { replace: true });
      } else if (!location.pathname.includes(team.id) && !user.state.isStaff) {
        navigate(`/${team.id}/events/agenda`, { replace: true });
      } else if (!location.pathname.includes("/teams/") && user.state.isStaff) {
        navigate(`/${team.id}/events/agenda`, { replace: true });
      }

      setTeam(team);
    }
  }, [
    location.pathname,
    location.search,
    teams.data,
    teams.isFetched,
    user.state.email,
    user.state.teamID,
    user.state.isStaff,
  ]);

  useEffect(() => {
    if (
      user.team?.id &&
      window.Intercom &&
      window.intercomSettings?.company?.company_id !== user.team?.id
    ) {
      const company = { company_id: user.team.id };
      window.intercomSettings.company = company;
      window.Intercom("update", { company });
      fetchSource(user.team.id);
    }
  }, [user.team]);

  useEffect(() => {
    if (team?.id) {
      const teamID = team?.id?.replace("teams/", "");
      if (window.intercomSettings && window.intercomSettings.teamID !== teamID)
        window.intercomSettings.teamID = teamID;
      if (window.Intercom) window.Intercom("update", window.intercomSettings);
    }
  }, [team]);
  return !socket ? (
    <div className="vh-100 p-5 d-flex justify-content-center align-items-center">
      <div className="spinner-border" role="status"></div>
    </div>
  ) : location.pathname === "/teams/new" ? (
    <Suspense>
      <Onboarding />
    </Suspense>
  ) : !team ? (
    <div className="vh-100 p-5 d-flex justify-content-center align-items-center">
      <div className="spinner-border" role="status"></div>
    </div>
  ) : location.pathname.includes('/monitor') ? (
    <Suspense>
      <Monitor />
    </Suspense>
  ) : (
    <Suspense>
      <CheckLicenses uid={user.state?.uid}>
        {(!team.locked || user.state.isStaff) && (
          <Navbar />
        )}
        {team.locked && (
          <Locked />
        )}
        <Router>
          <Consumers path="/teams/:teamID/consumers/*" />
          {(!team.locked || user.state.isStaff) && (
            <>
              <Events path="/teams/:teamID/events/*" />
              <Setup path="/teams/:teamID/setup/*" />
            </>
          )}
          <SubscriptionForm path="/teams/:teamID/suscripcion" />
          <SubscriptionForm path="/teams/:teamID/subscription" />
        </Router>
        <Notify />
        <Consumer />
        <MessagesForm />
        <Location />
        <ReactTooltip
          html
          globalEventOff="tooltip:hide"
          effect="solid"
        />
        {rooms.ids.map((id) => (
          <Room key={`room-${id}`} name={id} onClose={() => rooms.close(id)} />
        ))}
        <Prescription />
      </CheckLicenses>
    </Suspense>
  );
}

export default App;
