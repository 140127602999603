const { REACT_APP_GOOGLE_API_KEY } = process.env;

const URL = "https://maps.googleapis.com/maps/api";

let service;
let sessionToken;
const geo = {};

const waitForGoogleMaps = async () => {
  const step = 200;
  const lodaded = () =>
    window.google &&
    window.google.maps &&
    window.google.maps.places &&
    window.google.maps.places.AutocompleteService;

  for (let time = 0; time < 4000 && !lodaded(); time += step) {
    await new Promise((resolve) => setTimeout(step, resolve));
  }

  if (!lodaded()) {
    try {
      window.notify({
        message: "Google Maps Error",
        type: "danger",
        title: "Google Maps",
      });
    } catch {}
    throw Error("google_maps_error");
  } else {
    service = service || new window.google.maps.places.AutocompleteService();
    sessionToken =
      sessionToken || new window.google.maps.places.AutocompleteSessionToken();
  }
};

const parseAddressComponent = (hash, component) => {
  if (!Array.isArray(component.types)) return hash;
  const key = component.types.filter((type) => type !== "political")[0];
  if (!key) return hash;
  let long = component.long_name;
  let short = component.short_name;
  if (
    key === "sublocality_level_1" &&
    (/^palermo/i.test(long) || /^palermo/i.test(short))
  ) {
    long = "Palermo";
    short = "Palermo";
  } else if (
    key === "administrative_area_level_1" &&
    /buenos aires/i.test(short)
  ) {
    short = "Buenos Aires";
    long = "Buenos Aires";
  } else if (key === "administrative_area_level_1" && /^caba$/i.test(short)) {
    short = "CABA";
    long = "Capital Federal";
  }
  const payload = { long, short };
  return { ...hash, [key]: payload };
};

const getNeighborhood = (hash) => {
  if (hash.neighborhood) return hash.neighborhood;
  if (
    hash.sublocality_level_1 &&
    hash.administrative_area_level_1 &&
    hash.administrative_area_level_1.short === "CABA"
  ) {
    return { ...hash.sublocality_level_1 };
  } else if (
    hash.sublocality &&
    hash.administrative_area_level_1 &&
    hash.administrative_area_level_1.short === "CABA"
  ) {
    return { ...hash.sublocality };
  }
};

const getHash = (payload) => {
  const hash = payload.address_components.reduce(parseAddressComponent, {});
  hash.neighborhood = getNeighborhood(hash);
  hash.geo = payload.geometry;
  hash.place_id = payload.place_id;
  return hash;
};

geo.autocomplete = async (input, options) => {
  try {
    await waitForGoogleMaps();
  } catch {
    return [];
  }

  if (!input || !input.trim() || !service) return [];

  return new Promise((resolve, reject) => {
    const params = { input, sessionToken, ...options };
    service.getPlacePredictions(params, (results, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK)
        return reject(status);
      const items = results.map((item) => ({
        place_id: item.place_id,
        description: item.description,
        main: ((item || {}).structured_formatting || {}).main_text,
        secondary: ((item || {}).structured_formatting || {}).secondary_text,
      }));
      resolve(items);
    });
  });
};

geo.details = async (place_id, options) => {
  const params = new URLSearchParams({
    key: REACT_APP_GOOGLE_API_KEY,
    place_id,
    ...options,
  });

  if (!params.get("key") || !params.get("place_id")) return {};

  return fetch(`${URL}/geocode/json?${params.toString()}`)
    .then(res => res.json())
    .then(res => res.results[0] || {})
    .then(res => getHash(res))
    .catch(e => console.error('geo.details', e))
};

export default geo;
