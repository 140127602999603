import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typeahead } from "react-bootstrap-typeahead";

import geo from "./geo";

function Address(props) {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [searching, setSearching] = useState();

  const onInputChange = async (query) => {
    setOptions([]);
    setSearching(true);
    const options =
      (await geo.autocomplete(query, props.autocomplete).catch(console.log)) ||
      [];
    setOptions(options);
    setSearching(false);
  };

  const onChange = (value) => {
    if (props.onChange) props.onChange({ ...value });
  };

  const onSelect = async (array) => {
    const value = array[0] || {};
    onChange({ ...value });
    value.place_id = value.place_id || value.id || value.reference;
    if (value && value.place_id) {
      const details = await geo.details(value.place_id).catch(console.log);
      const payload = { ...value, ...details };
      if (details) onChange(payload);
    }
  };

  return (
    <Typeahead
      shouldSelect
      isLoading={!!searching}
      inputProps={{
        required: true,
        id: props.id || "address",
        autoComplete: "off",
        disabled: !!props.disabled,
      }}
      minLength={2}
      id={props.id || "address"}
      labelKey="description"
      onChange={onSelect}
      options={options}
      filterBy={() => true}
      onInputChange={onInputChange}
      selected={[props.value].filter((p) => p && p.description)}
      placeholder={
        props.placeholder || t(`address_placeholder`, { context: "address" })
      }
      newSelectionPrefix={null}
      emptyLabel={searching ? t(`searching`) : t(`no_matches`)}
      paginationText={t(`display_more_results`, { context: "address" })}
      promptText={t(`type_to_search`, { context: "address" })}
      searchText={t(`searching`, { context: "address" })}
      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
        <div className="form-floating">
          <input
            {...inputProps}
            autoFocus={props.autoFocus}
            type="text"
            className={`form-control${props.borderless ? ' border-0 rounded-0' : ''}${props.transparent ? ' bg-transparent' : ''}`}
            id={`floatingInput${props.id}`}
            placeholder={
              props.placeholder ||
              t(`address_placeholder`, { context: "address" })
            }
            ref={(input) => {
              // Be sure to correctly handle these refs. In many cases, both can simply receive
              // the underlying input node, but `referenceElementRef can receive a wrapper node if
              // your custom input is more complex (See TypeaheadInputMulti for an example).
              inputRef(input);
              referenceElementRef(input);
            }}
          />
          <label htmlFor={`floatingInput${props.id}`}>{props.label}</label>
        </div>
      )}
    />
  );
}

export default React.memo(Address);
